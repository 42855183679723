import { baseApiURL } from "@/config/env.js";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import store from '../store/index.js';
import globals from "@/config/globals.js";
import { i18n } from '../main';

let emergencyStopAll = false;

const getAPI = async (path, data = {}, with_token = false) => {

 if (emergencyStopAll) {
     return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
 }

 var headers = {};

 data.language_id = globals.language_id[i18n.locale];

 var passed_access_token = false;
 if ((store.state.isLoggedIn && store.state.access_token && store.state.access_token.trim()) || with_token) {
     headers['Authorization'] = `Bearer ${store.state.access_token}`;
     passed_access_token = true;
 }
 var response = await axios.get(`${baseApiURL}${path}`, {
     params: data,
     headers: headers
 }).catch((response) => response.response);

 if (passed_access_token && response.data.code == 'invalid_token') {
     
     emergencyStopAll = true;

     const $toast = useToast();
     $toast.open({
         message: response.data.message,
         type: 'error',
         position: 'bottom',
         queue: true,
         duration: 5000,
         onDismiss: function() {
             window.location = "/";
         }
     });
 }

 return response.data;
}

const postAPI = async (path, data = {}, with_token = false) => {
 var headers = {};

 if (emergencyStopAll) {
     return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
 }

 data.language_id = globals.language_id[i18n.locale];

 var passed_access_token = false;
 if ((store.state.isLoggedIn && store.state.access_token && store.state.access_token.trim()) || with_token) {
     headers['Authorization'] = `Bearer ${store.state.access_token}`;
     passed_access_token = true;
 }
 var response = await axios({
     method: 'post',
     url: `${baseApiURL}${path}`,
     headers: headers,
     params: data,
 }).catch((response) => response.response);

 if (passed_access_token && response.data.code == 'invalid_token') {
     
     emergencyStopAll = true;

     const $toast = useToast();
     $toast.open({
         message: response.data.message,
         type: 'error',
         position: 'bottom',
         queue: true,
         duration: 5000,
         onDismiss: function() {
             window.location = "/";
         }
     });
 }

 return response.data;
}

const getGuestID = () => {
 if (!localStorage.guest_id) {
     localStorage.guest_id = uuidv4();
 }
 return localStorage.guest_id;
}

export { 
 getAPI,
 postAPI,
 getGuestID
};